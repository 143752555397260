import './Onboarding.css';
import OnboardingMobileContent from "./OnboardingMobileContent/OnboardingMobileContent";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect';
// import PageDots from '../PageDots/PageDots';

const Onboarding = ({setHelpScreenVisible, logStartButtonClicked}) => {

    async function GetOnboardingData(){ 
        if (isMobile) {
            let response = await fetch("./data/onboardingContentsMobile.json");
            setOnboardingContents(await response.json());
        } else {
            let response = await fetch("./data/onboardingContentsDesktop.json");
            setOnboardingContents(await response.json());
        }
        setPageIndex(0);
        setDataLoaded(true);
    };

    useEffect(() => {
         GetOnboardingData()}, []);

    
    const [onboardingContents, setOnboardingContents] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    
    const buttonClickHandler = function() {
        goToNextPage();
    }

    const goToNextPage = () => {
        if ((pageIndex + 1) > onboardingContents.length - 1) {
            logStartButtonClicked();
            setHelpScreenVisible(false);
            return;
        }
        setPageIndex(pageIndex + 1);
    }

    return (
    <div className="onboarding">
        <div className="onboarding-box">
            {dataLoaded &&
        <div className="onboarding-container">
        <img src="./media/Mastercard-logo-no-wordmark.png" className="mastercard-logo" alt="Mastercard logo"/>
        <p className="onboarding-title">Welcome to the Mastercard holiday card experience!</p>
        <OnboardingMobileContent content={onboardingContents[pageIndex]}/>
        <button onClick={buttonClickHandler} className="btn-enter btn-rounded mc-white">
            {onboardingContents[pageIndex].button === "next" ? <FontAwesomeIcon icon={faArrowRight} /> : <>Start <FontAwesomeIcon icon={faArrowRight} /></>}
        </button>
        {/* <PageDots className="page-dots-container" onboardingContents={onboardingContents} pageIndex={pageIndex}/> */}
        </div>}
    </div>
    </div>);
}
 
export default Onboarding;