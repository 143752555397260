import './App.css';
import { useRef, useState } from 'react';
import ModelViewer from './Components/ModelViewer/ModelViewer';
import Onboarding from './Components/Onboarding/Onboarding';
import MuteToggleButton from './Components/MuteToggleButton/MuteToggleButton';
import ARButton from './Components/ARButton/ARButton';
import QRCodeModal from './Components/QRCodeModal/QRCodeModal';
import IntroScreen from './Components/IntroScreen/IntroScreen';
import Help from './Components/Help/Help';
import HelpButton from './Components/HelpButton/HelpButton';
import ReadMoreButton from './Components/ReadMoreButton/ReadMoreButton';



function App() {
  const audioPlayer = useRef(document.querySelector(".audio-player"));
  const [helpScreenVisible, setHelpScreenVisible] = useState(true);
  const [helpModalScreenVisible, setHelpModalScreenVisible] = useState(false);
  const [qrCodeModalVisible, setQRCodeModalVisible] = useState(false);
  const [introScreenVisible, setIntroScreenVisible] = useState (true);
  const [currentPresentSelected, setCurrentPresentSelected] = useState();
  const [isMuted, setIsMuted] = useState(false);
  const presents = [
    {name:"Stand Up To Cancer",
    viewerUrl:"https://reybeam.reydar.com/?config_uuid=76ea7295-6c0d-4b4d-b9cd-bb5766ff99ca",
    charityURL: "https://standuptocancer.org/who-we-are/",
    androidModel:"intent://arvr.google.com/scene-viewer/1.0?file=https://cdn.enginecloud.co.uk/models/e8ee7e7f-065a-4618-945a-b7921c6c4e74/e22eea6e-f03f-46ce-b8f3-bcaa5b0361c5.glb&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://mastercardholidaycard.enginecloud.co.uk;end;",
    appleModel:"https://cdn.enginecloud.co.uk/models/e8ee7e7f-065a-4618-945a-b7921c6c4e74/e22eea6e-f03f-46ce-b8f3-bcaa5b0361c5.usdz"},
    {name:"Priceless Planet Coalition",
    prefix: "the ",
    viewerUrl:"https://reybeam.reydar.com/?config_uuid=2c7a3ec6-94b4-4197-a428-39a64b30dd2d",
    charityURL: "http://pricelessplanet.org/",
    androidModel:"intent://arvr.google.com/scene-viewer/1.0?file=https://cdn.enginecloud.co.uk/models/89f164f3-f724-4f89-8165-f67ba6dd976d/94298952-2e21-4e0b-85ba-bb4875ba84d3.glb&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://mastercardholidaycard.enginecloud.co.uk;end;",
    appleModel:"https://cdn.enginecloud.co.uk/models/89f164f3-f724-4f89-8165-f67ba6dd976d/94298952-2e21-4e0b-85ba-bb4875ba84d3.usdz"}
];

  const toggleMute = () => {
    logToggleMuteButtonClicked();
    setIsMuted(!isMuted);
    audioPlayer.current.muted = !isMuted;
  }
  
  const logStartButtonClicked = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'start_experience'});
  }
  const logArButtonClickedDesktop = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'ar_button_desktop'});
  }
  const logArButtonClickedMobile = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'ar_button_mobile'});
  }
  const logToggleMuteButtonClicked = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'toggle_mute_button'});
  }

  const presentSelected = () => {
    setIntroScreenVisible(false);
    const random = Math.floor(Math.random() * presents.length);
    const present = presents[random];
    setCurrentPresentSelected(present);
    playAudio();
  }

  const handleHelpSelected = () => {
    setHelpModalScreenVisible(true);
  }

  const playAudio = () => {
    audioPlayer.current.play();
  }
  
  return (
    <div className="App">
      <audio ref={audioPlayer} src="./media/MC_AudioMASTER_Anthem2021_Christmas_0dBFS-1.mp3" loop={true} className="hidden audio-player"></audio>
      {
        introScreenVisible ? <IntroScreen onPresentSelected={presentSelected}/> :  <ModelViewer modelViewerUrl={currentPresentSelected.viewerUrl}/>
      }
      {!introScreenVisible &&
      <>
        <MuteToggleButton isMuted={isMuted} toggleMute={toggleMute}/>
        <ARButton 
          setQRCodeModalVisible={setQRCodeModalVisible}
          logArButtonClickedDesktop={logArButtonClickedDesktop} 
          logArButtonClickedMobile={logArButtonClickedMobile}
          currentPresentSelected={currentPresentSelected}>
        </ARButton>
        <HelpButton handleHelpSelected={handleHelpSelected}/>
        <ReadMoreButton buttonUrl={currentPresentSelected.charityURL} charityName={currentPresentSelected.name} prefix={currentPresentSelected.prefix} />
      </>}
      {qrCodeModalVisible && <QRCodeModal setQRCodeModalVisible={setQRCodeModalVisible}></QRCodeModal>}
      {
      helpScreenVisible && <Onboarding
      setHelpScreenVisible={setHelpScreenVisible} 
      logStartButtonClicked={logStartButtonClicked} />}
      {helpModalScreenVisible && <Help
      setHelpModalScreenVisible={setHelpModalScreenVisible}/>}
    </div>
  );
}

export default App;
