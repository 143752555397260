import './ReadMoreButton.css';

const ReadMoreButton = ({buttonUrl, charityName, prefix}) => {
    return ( 
        <>
        <div className="slide-up-fade-in rm-container rm-container-top">
            <p>You've unwrapped a contribution to {prefix}{charityName}!</p>
            <p><i>Zoom to enlarge your gift note and image.</i></p>
        </div>
        <div className="slide-up-fade-in rm-container">
            <a className="btn-rmb" href={buttonUrl} target="_blank" rel="noreferrer">Learn more about {charityName}</a>
        </div>
        </>
    );
}
 
export default ReadMoreButton;