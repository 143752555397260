import "./OnboardingMobileContent.css"

const OnboardingMobileContent = (props) => {
    return ( <div className="box">
        <img src={props.content.iconPath} alt="AR Icon" className="ar-icon"></img>
        <p className="onboarding-text">{props.content.line1}</p><br/>
        <p className="onboarding-text">{props.content.line2}</p>
    </div> );
}
 
export default OnboardingMobileContent;