import "./HelpMobileContent.css"

const OnboardingMobileContent = (props) => {
    return ( <div className="box">
        <img src={props.content.iconPath} alt="AR Icon" className="ar-icon"></img>
        <p className="help-text">{props.content.line1}</p>
        <p className="help-text">{props.content.line2}</p>
    </div> );
}
 
export default OnboardingMobileContent;