import './Help.css';
import HelpMobileContent from "./helpMobileContent/HelpMobileContent";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect';
import PageDots from '../PageDots/PageDots';

const Help = ({setHelpModalScreenVisible, logStartButtonClicked, playAudio}) => {

    async function GetHelpData(){ 
        if (isMobile) {
            let response = await fetch("./data/helpContentsMobile.json");
            setHelpContents(await response.json());
        } else {
            let response = await fetch("./data/helpContentsDesktop.json");
            setHelpContents(await response.json());
        }
        setPageIndex(0);
        setDataLoaded(true);
    };

    useEffect(() => {
         GetHelpData()}, []);

    
    const [helpContents, setHelpContents] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    
    const buttonClickHandler = function() {
        goToNextPage();
    }

    const goToNextPage = () => {
        if ((pageIndex + 1) > helpContents.length - 1) {
            setHelpModalScreenVisible(false);
            return;
        }
        setPageIndex(pageIndex + 1);
    }

    return (
    <div className="help">
        <div className="help-box">
            {dataLoaded &&
        <div className="help-container">
        <img src="./media/Mastercard-logo-no-wordmark.png" className="mastercard-logo" alt="Mastercard logo"/>
        <p className="help-title">How to use the Mastercard Holiday Season AR experience</p>
        <HelpMobileContent content={helpContents[pageIndex]}/>
        <button onClick={buttonClickHandler} className="btn-enter btn-rounded mc-white">
            {helpContents[pageIndex].button === "next" ? <FontAwesomeIcon icon={faArrowRight} /> : <>Finish <FontAwesomeIcon icon={faArrowRight} /></>}
        </button>
        <PageDots className="page-dots-container" onboardingContents={helpContents} pageIndex={pageIndex}/>
        </div>}
    </div>
    </div>);
}
 
export default Help;