import "./ARButton.css"
import { isMobile } from 'react-device-detect';

const ARButton = (props) => {

    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }


    let modelUrl = null;

    if(isMobile) {
        if(iOS()) {
            modelUrl = props.currentPresentSelected.appleModel
        } else {
            modelUrl = props.currentPresentSelected.androidModel
        }
    }

    const openModelUrl = () => window.location.href = modelUrl;

    const onButtonClicked = () => {
        if(isMobile) {
            props.logArButtonClickedMobile();
        } else {
            props.logArButtonClickedDesktop();
            props.setQRCodeModalVisible(true);
        }
    }
    return ( 
        isMobile ? (<a rel="ar" onClick={onButtonClicked} className="btn-ar" href={modelUrl}>
            <img style={{"display": "none"}} alt=""/>
            <svg alt="AR Button" width="96" height="40" viewBox="0 0 96 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="box-shape" d="M39 28.2961L36 26.6665V22.9628M36 17.0369V13.3332L39 11.7035M45 8.29613L48 6.6665L51 8.29613M57 11.7035L60 13.3332V17.0369M60 22.9628V26.6665L57 28.3258M51 31.7035L48 33.3332L45 31.7035M48 19.9998L51 18.3702M57 14.9628L60 13.3332M48 19.9998V23.7035M48 29.6295V33.3332M48 19.9998L45 18.3406M39 14.9628L36 13.3332" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="40" y="12" width="16" height="16" rx="8" fill="black"/>
<path className="ar-text" d="M48.2732 22L46.3052 16.664H44.2732L42.3052 22H44.1372L44.3292 21.376H46.2492L46.4412 22H48.2732ZM45.8172 19.968H44.7612L45.2892 18.248L45.8172 19.968ZM53.3369 22L52.3689 20.112C52.7929 19.904 53.3289 19.416 53.3289 18.496C53.3289 17.496 52.6569 16.664 51.4089 16.664H48.5609V22H50.1769V20.32H50.7609L51.4969 22H53.3369ZM51.1609 18.912H50.1769V18.072H51.1609C51.4329 18.072 51.6889 18.2 51.6889 18.488C51.6889 18.784 51.4329 18.912 51.1609 18.912Z" fill="#F1EFEB"/>
<rect className="outline" x="1" y="1" width="94" height="38" rx="19" stroke="#F1EFEB" strokeWidth="2"/>
</svg>
</a>) : (
                <svg className="btn-ar" onClick={onButtonClicked} alt="AR Button" width="96" height="40" viewBox="0 0 96 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="box-shape" d="M39 28.2961L36 26.6665V22.9628M36 17.0369V13.3332L39 11.7035M45 8.29613L48 6.6665L51 8.29613M57 11.7035L60 13.3332V17.0369M60 22.9628V26.6665L57 28.3258M51 31.7035L48 33.3332L45 31.7035M48 19.9998L51 18.3702M57 14.9628L60 13.3332M48 19.9998V23.7035M48 29.6295V33.3332M48 19.9998L45 18.3406M39 14.9628L36 13.3332" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="40" y="12" width="16" height="16" rx="8" fill="black"/>
                <path className="ar-text" d="M48.2732 22L46.3052 16.664H44.2732L42.3052 22H44.1372L44.3292 21.376H46.2492L46.4412 22H48.2732ZM45.8172 19.968H44.7612L45.2892 18.248L45.8172 19.968ZM53.3369 22L52.3689 20.112C52.7929 19.904 53.3289 19.416 53.3289 18.496C53.3289 17.496 52.6569 16.664 51.4089 16.664H48.5609V22H50.1769V20.32H50.7609L51.4969 22H53.3369ZM51.1609 18.912H50.1769V18.072H51.1609C51.4329 18.072 51.6889 18.2 51.6889 18.488C51.6889 18.784 51.4329 18.912 51.1609 18.912Z" fill="#F1EFEB"/>
                <rect className="outline" x="1" y="1" width="94" height="38" rx="19" stroke="#F1EFEB" strokeWidth="2"/>
                </svg>
))

}
 
export default ARButton;