import './ModelViewer.css';

const ModelViewer = ({modelViewerUrl}) => {
    return (<iframe
        title="reydar-viewer"
        className="reydar-viewer-frame"
        frameBorder="0"
        allowFullScreen=""
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking; clipboard-write;"
        xr-spatial-tracking=""
        execution-while-out-of-viewport=""
        execution-while-not-rendered=""
        web-share=""
        width="100%"
        height="100%"
        src={modelViewerUrl}
      ></iframe>);
}
 
export default ModelViewer;