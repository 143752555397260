import './IntroScreen.css';
import handicon from '../../Assets/Mastercard_hand_256x256.png';
import boximagery from '../../Assets/MC_NAM_BoxTrio_new.png';

const IntroScreen = ({onPresentSelected}) => {
    return (
    <div className="intro-screen-content">
        <div className="icon-img-container">
            <img src={handicon} alt="hand-icon"/>
        </div>
        <p className="heading">Our partnership is <strong>priceless</strong>.<br/>To celebrate, we've made a contribution to one of<br/>our <strong>priceless</strong> cause partners.</p>
        <p className="sub-p">Select a present below to unwrap your surprise.</p>
        <div className="middle-content">
            <button className='present-btn' onClick={onPresentSelected}>
                <img src={boximagery} alt="present-trio" className="present-imagery"/>
            </button>
            <div className="IS-lower-content">
                <div className="lower-c-grid">
                    {/* <div className="lower-c-cell">
                        <button className="open-present-button" onClick={onPresentSelected}>Open Gift</button>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="legal"><p><i>Mastercard will make a donation to Conservation International, in support of the mission of the Priceless Planet Coalition, and to Stand Up To Cancer.  Conservation International and Stand Up To Cancer are each a U.S. 501(c)(3) charity. If you would like to learn more, you can click here for <a href="https://www.mastercard.us/en-us/vision/corp-responsibility/priceless-planet.html">Priceless Planet Coalition</a> and here for<a href="https://standuptocancer.org/who-we-are/">Stand Up To Cancer.</a></i></p></div>
    </div>
    );
}
 
export default IntroScreen;